define("frontend/components/author-row", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    errors: null,
    /*
    resetForm: function() {
      if (this.get("item.newAuthorForm")) {
        if(!this.get('item.importedAuthorName')) {
          this.set("item.newAuthorForm.firstName", '');
          this.set("item.newAuthorForm.lastName", '');
        }
        this.set("item.newAuthorForm.year_of_birth", '');
        this.set("item.newAuthorForm.xaccount", '');
        this.set("item.newAuthorForm.orcid", '');
      }
    },
    */

    isFirst: _ember["default"].computed("index", function () {
      if (this.get("index") === 0) {
        return "is-first";
      }
      return;
    }),

    isLast: _ember["default"].computed("index", "totalNumberOfItems", function () {
      if (this.get("index") + 1 === this.get("totalNumberOfItems")) {
        return "is-last";
      }
      return;
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // Helper function for persisting new author items, returns promise
      this.set("createAuthor", function (item) {
        return _this.store.save("person", {
          first_name: item.newAuthorForm.get("firstName"),
          last_name: item.newAuthorForm.get("lastName"),
          year_of_birth: item.newAuthorForm.get("year_of_birth"),
          xaccount: item.newAuthorForm.get("xaccount"),
          orcid: item.newAuthorForm.get("orcid"),
          skip_update_search_engine: item.newAuthorForm.get("skip_update_search_engine")
        }).then(function (model) {
          if (model.error) {
            return _ember["default"].RSVP.reject(model);
          }
          item.set("selectedAuthor", model);
          item.set("transformedToNewAuthor", false);
        });
      });

      //TODO: Forgotten how to Ember, is this correct or should be property on object sent to extend??
      this.set("invalidSelectedDepartments", _ember["default"].A([]));

      this.get("submitCallbacks").addObject(function () {
        if (_this.get("isUnsaved") || _this.get("isUnsavedImported")) {
          //TODO: promt user if isUnsaved? "Save", "Discard", "Cancel"?
          var item = _this.get("item");
          if (_this.get("isUnsavedImported")) {
            item.newAuthorForm.set("firstName", item.get("importedAuthorFirstName"));
            item.newAuthorForm.set("lastName", item.get("importedAuthorLastName"));
          }
          // TODO: Why do we do this?
          item.newAuthorForm.set("skip_update_search_engine", true);
          return _this.get("createAuthor")(item);
        }
        return _ember["default"].RSVP.Promise.resolve();
      });
    },
    // Helper
    // Could be generalized, with dynamic prop and made global helper
    getDepartmentIds: function getDepartmentIds(departments) {
      return departments.reduce(function (result, department) {
        result[department.id] = department.id; //TODO: or null?
        return result;
      }, []);
    },
    selectedAuthorChanged: _ember["default"].observer("item.selectedAuthor", function () {
      var _this2 = this;

      // set selectedInstitution to departments of selected author
      if (this.get("item.selectedAuthor") && this.get("item.selectedAuthor.departments")) {
        // find first department in list of departmentIDs
        this.get("item.selectedAuthor.departments").every(function (department) {
          if (_this2.get("departmentIds")[department.id] !== undefined) {
            _this2.set("item.selectedInstitution", [department]);
            return false;
          }
        });
      }
    }),

    getCssId: _ember["default"].computed("item", function () {
      return "_" + this.get("item.id");
    }),

    departmentIds: _ember["default"].computed("institutions.[]", function () {
      //TODO: or this.get(?
      return this.getDepartmentIds(this.get("institutions"));
    }),

    departmentsChanged: _ember["default"].observer("institutions.[]", function () {
      var _this3 = this;

      // Restore all departments no longer invalid
      var invalid_selected_departments_was_present = _ember["default"].isPresent(this.get("invalidSelectedDepartments"));
      var restored_departments = [];
      var invalid_selected_departments = this.get("invalidSelectedDepartments").filter(function (department) {
        var restored_department = _this3.get("institutions").findBy("id", department.id);
        if (restored_department) {
          restored_departments.push(restored_department);
          return false;
        }
        return true;
      });

      if (_ember["default"].isPresent(restored_departments)) {
        this.get("item.selectedInstitution").pushObjects(restored_departments);
        this.set("invalidSelectedDepartments", invalid_selected_departments);
      }

      // Are any of the selected institutions no longer within the selectable institutions
      var department_ids = this.get("departmentIds");
      var removed_departments = [];
      var valid_selected_departments = this.get("item.selectedInstitution").filter(function (department) {
        if (department_ids[department.id] === undefined) {
          removed_departments.push(department);
          return false;
        }
        return true;
      });
      this.set("item.selectedInstitution", valid_selected_departments);

      removed_departments.forEach(function (department) {
        var active_years = "";
        if (department.start_year || department.end_year) {
          active_years = " (" + (department.start_year || "?") + " - " + (department.end_year || "") + ")";
        }
        // Create peudo department objects since we loose reference to "real" department object
        // if removed for selectable department we can later retrieve the department by id if appears again
        _this3.get("invalidSelectedDepartments").pushObject({
          id: department.id,
          info: department.name + active_years
        });
      });

      var invalid_selected_deparments_is_present = _ember["default"].isPresent(this.get("invalidSelectedDepartments"));
      if (invalid_selected_departments_was_present) {
        if (!invalid_selected_deparments_is_present) {
          this.get("onInvalidSelectedDepartmentsEmpty")();
        }
      } else if (invalid_selected_deparments_is_present) {
        this.get("onInvalidSelectedDepartmentsPresent")();
      }
      /*
      if(Ember.isPresent(restored_departments) || Ember.isPresent(removed_departments)) {
        this.get('invalidSelectedDepartmentsChanged')(this.get('invalidSelectedDepartments'));
      }
      */
    }),

    validDepartmentSuggestions: _ember["default"].computed("item.selectedAuthor", "departmentIds", function () {
      var _this4 = this;

      var author_departments = this.get("item.selectedAuthor.departments");
      if (_ember["default"].isArray(author_departments)) {
        var _ret = (function () {
          // Create array keyed by institution id for faster lookup
          var department_ids = _this4.get("departmentIds");
          return {
            v: author_departments.filter(function (department) {
              // Filter out departments not present in selectable institutions
              return department_ids[department.id] !== undefined;
            }).map(function (department) {
              return _ember["default"].Object.create({
                name: department.name,
                department: department
              });
            })
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      }
      return _ember["default"].A([]);
    }),

    selectedDepartmentIds: _ember["default"].computed("item.selectedInstitution.[]", function () {
      return this.getDepartmentIds(this.get("item.selectedInstitution"));
    }),

    nonSelectedValidDepartmentSuggestions: _ember["default"].computed("validDepartmentSuggestions", "selectedDepartmentIds", function () {
      var selected_department_ids = this.get("selectedDepartmentIds");
      if (_ember["default"].isPresent(selected_department_ids)) {
        return this.get("validDepartmentSuggestions").filter(function (suggestion) {
          return selected_department_ids[suggestion.get("department").id] === undefined;
        });
      }
      return this.get("validDepartmentSuggestions");
    }),

    nonSelectedDepartmentSuggestions: _ember["default"].computed("departmentSuggestions.@each.selected", function () {
      return this.get("departmentSuggestions").filterBy("selected", false);
    }),

    // Used to signal select2-adjusted component to set a default query string
    setDefaultQuery: _ember["default"].computed("item.importedAuthorName", "item.selectedInstitution", "item.selectedAuthor.last_name", "item.newAuthorForm.lastName", function () {
      return _ember["default"].isEmpty(this.get("item.newAuthorForm.lastName")) && (_ember["default"].isPresent(this.get("item.importedAuthorName")) || _ember["default"].isEmpty(this.get("item.selectedInstitution")) && _ember["default"].isPresent(this.get("item.selectedAuthor.last_name")));
    }),

    defaultQuery: _ember["default"].computed("item.importedAuthorLastName", "item.selectedInstitution", "item.selectedAuthor.last_name", "item.newAuthorForm.lastName", function () {
      if (_ember["default"].isEmpty(this.get("item.newAuthorForm.lastName"))) {
        if (_ember["default"].isPresent(this.get("item.importedAuthorName"))) {
          return this.get("item.importedAuthorName");
        } else if (_ember["default"].isEmpty(this.get("item.selectedInstitution")) && _ember["default"].isPresent(this.get("item.selectedAuthor.last_name"))) {
          return this.get("item.selectedAuthor.last_name");
        }
      }
    }),

    importedAuthorName: _ember["default"].computed("item.importedAuthorName", "item.selectedInstitution", "item.selectedAuthor.presentation_string", function () {
      if (_ember["default"].isPresent(this.get("item.importedAuthorName"))) {
        return this.get("item.importedAuthorName");
      } else if (_ember["default"].isEmpty(this.get("item.selectedInstitution"))) {
        return this.get("item.selectedAuthor.presentation_string");
      }
    }),

    isImportedExternal: _ember["default"].computed("importedAuthorName", "addAffiliation", function () {
      return this.get("importedAuthorName") && !this.get("addAffiliation");
    }),

    isUnsaved: _ember["default"].computed("item.transformedToNewAuthor", "item.newAuthorForm.lastName", function () {
      return this.get("item.transformedToNewAuthor") && !_ember["default"].isBlank(this.get("item.newAuthorForm.lastName"));
    }),

    isUnsavedImported: _ember["default"].computed("isUnsaved", "item.selectedAuthor", "item.importedAuthorName", function () {
      //TODO check for lastname or see where is set
      return !this.get("isUnsaved") && !_ember["default"].isPresent(this.get("item.selectedAuthor")) && this.get("item.importedAuthorName");
    }),

    //isEmpty: Ember.computed('item.
    /*
    newAuthorFormVisible: function() {
      var self = this;
      if (this.get('item.transformedToNewAuthor')) {
        this.resetForm();
        Ember.run.later(function() {
          self.$().find('#first-name').focus();
        });
      }
    }.observes('item.transformedToNewAuthor'),
    */

    transformedNewAuthorTriggered: (function () {
      if (this.get("item.transformedToNewAuthor")) {
        this.send("showAddNewAuthorForm");
      }
    }).observes("item.transformedToNewAuthor"),

    actions: {
      authorInstitutionsChanged: function authorInstitutionsChanged(institutions) {
        this.set("item.selectedInstitution", institutions);
      },
      queryAuthors: function queryAuthors(query, deferred) {
        var _this5 = this;

        //TODO: This utility function should be accessible to other classes
        // put it somewhere else, in service?
        function zipDepartments(doc, locale) {
          var departments = [];
          if (_ember["default"].isArray(doc.departments_id)) {
            departments = doc.departments_id.map(function (department_id, index) {
              var start_year = doc["departments_start_year"][index];
              var end_year = doc["departments_end_year"][index];
              return {
                id: department_id,
                name: doc["departments_name_" + locale][index],
                start_year: start_year !== -1 ? start_year : null,
                end_year: end_year !== -1 ? end_year : null
              };
            });
          }
          return departments;
        }
        var result = this.store.find("person_gup_admin_record", {
          search_term: query.term
        });
        result.then(function (data) {
          data = data.map(function (item) {
            // Create presentation string
            var name = item.first_name + " " + item.last_name;
            var year = item.year_of_birth;
            var id = [item.xaccount, item.orcid].compact().join(", ");

            var departments = [];
            if (item.has_affiliations) {
              item.departments_id.forEach(function (department_id, index) {
                departments.push({
                  id: department_id,
                  name_sv: item.departments_presentation_name_sv[index],
                  name_en: item.departments_presentation_name_en[index]
                });
              });
            }
            var departments_str = "";
            departments.forEach(function (department, index) {
              if (index > 2) {
                return;
              }
              var department_name = department.name_sv;
              if (_this5.get("i18n.locale") === "en") {
                department_name = department.name_en;
              }
              departments_str += "<span class='department_name'>" + department_name + "</span>" + "<br/>";
            });
            item.presentation_string = _ember["default"].String.htmlSafe([name, year].compact().join(", ") + (id ? " " + ["(", id, ")"].join("") : "") + " " + "<br/><span class='departments small'>" + departments_str + "</span><br/>");
            //TODO: This is perhaps a little bit of a micro-opmtimization overkill
            // but instead of extracting departments greedily here
            // (there can be quite a lot of authors)
            // get departments lazily through getter function
            Object.defineProperty(item, "departments", {
              get: function get() {
                return zipDepartments(item, _this5.get("i18n.locale"));
              }
            });
            return item;
          });
          if (_this5.get("queryAuthorsResult")) {
            data = _this5.get("queryAuthorsResult")(data);
          }
          deferred.resolve(data);
        }, function (reason) {
          //warning?
          console.error(reason);
          deferred.reject(reason);
        });
      },
      moveUpOne: function moveUpOne(id) {
        this.sendAction("moveUp", id);
      },

      moveDownOne: function moveDownOne(id) {
        this.sendAction("moveDown", id);
      },

      remove: function remove(id) {
        this.sendAction("removeAuthor", id);
      },

      showAddAffiliation: function showAddAffiliation() {
        this.set("addAffiliation", true);
      },

      showAddNewAuthorForm: function showAddNewAuthorForm() {
        // TODO: item not used here
        // create new author
        this.set("item.newAuthorForm", _ember["default"].Object.create({
          // set imported author first and last name to imported author if any
          firstName: this.get("item.importedAuthorFirstName") ? this.get("item.importedAuthorFirstName") : "",
          lastName: this.get("item.importedAuthorLastName") ? this.get("item.importedAuthorLastName") : "",
          year_of_birth: "",
          xaccount: "",
          orcid: ""
        }));
        this.get("item").set("transformedToNewAuthor", true);
      },

      cancelAddNewAuthorForm: function cancelAddNewAuthorForm(item) {
        // TODO: item not used here
        this.get("item").set("transformedToNewAuthor", false);
        // Reset form
        this.set("item.newAuthorForm", _ember["default"].Object.create({
          firstName: "",
          lastName: "",
          year_of_birth: "",
          xaccount: "",
          orcid: ""
        }));
      },

      createAuthor: function createAuthor(item) {
        var _this6 = this;

        // TODO: Should validate required properties (lastName)!?
        this.get("createAuthor")(item)["catch"](function (reason) {
          //this.sendAction("setMsgHeader", "error", reason.error.msg);
          _this6.set("errors", reason.error.errors);
          //TODO: fix, schedule after render instead?
          /*         Ember.run.later(function () {
            Ember.$('[data-toggle="popover"]').popover({
              placement: "top",
              html: true,
            });
          }); */
        });
      },
      addInstitution: function addInstitution(institution) {
        // Add institution to selected array
        var selectedInstitutionCopy = _ember["default"].copy(this.get("item.selectedInstitution"));
        // Have to overwrite value, since select2 observes "value"
        // (in this case bound to selectedInstitutions)
        // but is not smart enough to detect changes within "value" (selectedInstitutions.[])
        //TODO: This is fubar
        var institutionObject = institution instanceof _ember["default"].Object ? institution : _ember["default"].Object.create(institution);
        selectedInstitutionCopy.addObject(institutionObject);
        this.set("item.selectedInstitution", selectedInstitutionCopy);
        // Add institution to select2 component
        /*
        var id = '#s2id_' + this.get('item.id');
        var institutionsElement = Ember.$(id).select2('data');
        institutionsElement.addObject(institutionObject);
        Ember.$(id).select2('data', institutionsElement);
        */
      }
    }
  });
});